import React, { useState, useEffect } from 'react';
import { ShieldX, CheckCircle2, Instagram, AlertCircle, CheckCircle, AlertTriangle, ChevronDown, ChevronUp, HelpCircle } from 'lucide-react';

const B112LandingPage = () => {
      const [message, setMessage] = useState('');
      const [isFocused, setIsFocused] = useState(false);
      const [notification, setNotification] = useState(null);
      const [openFaq, setOpenFaq] = useState(null);
      const [isLoading, setIsLoading] = useState(false);
      const [declinedMessages, setDeclinedMessages] = useState(0); // Ajout d'un état pour les messages refusés
      const [acceptedMessages, setAcceptedMessages] = useState(0); // Ajout d'un état pour les messages acceptés

      const MIN_MESSAGE_LENGTH = 5;
      const MAX_MESSAGE_LENGTH = 125;
      const SUCCESS_MESSAGE = "Message envoyé avec succès !";
      const ERROR_MESSAGE = "Une erreur est survenue, veuillez réessayer plus tard.";
      const RESET_TIMEOUT = 2500;

      const showCharCount = message.length > 100;

      const getUserIP = async () => {
            try {
                  const response = await fetch('https://api.ipify.org?format=json');
                  const data = await response.json();
                  return data.ip;
            } catch (error) {
                  console.error('Error getting user IP:', error);
                  return null;
            }
      };

      const sendDataToWebhook = async (message, userdata) => {
            const webhookUrl = 'https://webhook.anonymtell.workers.dev';
            const requestBody = JSON.stringify({ message, userdata });

            const response = await fetch(webhookUrl, {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: requestBody
            });

            return response;
      };

      const getcounter = async () => {
            try {
                  const response = await fetch('https://counter.anonymtell.workers.dev');
                  const data = await response.json();
                  return data;
            } catch (error) {
                  console.error('Error getting counter:', error);
                  return null;
            }
      };

      // Utilisation de useEffect pour récupérer les valeurs des compteurs lors du montage du composant
      useEffect(() => {
            const fetchCounters = async () => {
                  const counters = await getcounter();
                  if (counters) {
                        setDeclinedMessages(counters.declined);
                        setAcceptedMessages(counters.accepted);
                  }
            };
            fetchCounters();
      }, []); // Le tableau vide [] indique que cet effet doit se produire une seule fois lors du montage

      const handleSend = async () => {
            if (message.length < MIN_MESSAGE_LENGTH) {
                  showNotification('error', `Votre message doit contenir au moins ${MIN_MESSAGE_LENGTH} caractères.`);
                  return;
            }

            if (message.length > MAX_MESSAGE_LENGTH) {
                  showNotification('error', `Votre message doit contenir au maximum ${MAX_MESSAGE_LENGTH} caractères.`);
                  return;
            }

            setIsLoading(true);

            try {
                  const userip = await getUserIP();
                  const userdata = {
                        ip: userip,
                        screen: `${window.screen.width}x${window.screen.height}`,
                        device: navigator.platform,
                        language: navigator.language,
                  };

                  const response = await sendDataToWebhook(message, userdata);

                  if (response.ok) {
                        showNotification('success', SUCCESS_MESSAGE);
                        setMessage('');
                        setTimeout(() => {
                              showNotification(null);
                        }, RESET_TIMEOUT);
                  } else {
                        throw new Error('Webhook request failed');
                  }
            } catch (error) {
                  console.error('Error sending message:', error);
                  showNotification('error', ERROR_MESSAGE);
            } finally {
                  setIsLoading(false);
            }
      };

      const showNotification = (type, message) => {
            setNotification({ type, message });
      };

      const faqItems = [
            { question: "Qui sommes-nous ?", answer: "Nous sommes des gens comme vous, on a créé ce compte pour que vous puissiez vous exprimer librement et anonymement, dans la bienveillance. Pas de jugement, pas de haine." },
      ];

      const toggleFaq = (index) => {
            setOpenFaq(openFaq === index ? null : index);
      };

      return (
            <div className="flex flex-col justify-center items-center min-h-screen bg-gray-900 text-white font-poppins p-4">
                  <div className="w-full max-w-md flex flex-col items-center">
                        <a href="https://www.instagram.com/crushlamerci.l/" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center mb-4 text-2xl font-extrabold text-white hover:text-green-400 transition-colors">
                              <Instagram className="mr-2" size={28} />
                              Crush La Merci
                        </a>

                        <div className="flex justify-between w-full mb-4 gap-3">
                              <InfoBox title="Messages refusés" count={declinedMessages} icon={ShieldX} color="text-red-500" bgColor="bg-red-500/20" />
                              <InfoBox title="Messages postés" count={acceptedMessages} icon={CheckCircle2} color="text-green-500" bgColor="bg-green-500/20" />
                        </div>

                        <div className="relative w-full mb-4">
                              <textarea
                                    className="w-full h-40 bg-gray-800/80 rounded-xl p-3 text-2xl font-bold resize-none focus:outline-none focus:ring-2 focus:ring-green-500 font-poppins"
                                    maxLength={MAX_MESSAGE_LENGTH}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    disabled={isLoading}
                              />
                              <div className={`absolute inset-0 flex items-center justify-center text-2xl font-bold text-gray-400 pointer-events-none transition-opacity duration-300 ${(isFocused || message.length > 0) ? 'opacity-0' : 'opacity-100'}`}>
                                    Tape ton message
                              </div>
                              {showCharCount && (
                                    <div className="absolute bottom-2 right-4 text-sm font-bold text-gray-400">
                                          {message.length} / {MAX_MESSAGE_LENGTH}
                                    </div>
                              )}
                        </div>

                        <button
                              onClick={handleSend}
                              className={`w-full py-2 bg-gradient-to-r from-green-500 to-green-600 rounded-full text-lg font-extrabold transition-transform hover:-translate-y-1 font-poppins mb-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                              disabled={isLoading}
                        >
                              {isLoading ? 'ENVOI EN COURS...' : 'ENVOYER ANONYMEMENT'}
                        </button>

                        <NotificationArea notification={notification} />

                        <div className="w-full mt-4">
                              {faqItems.map((item, index) => (
                                    <div key={index} className="mb-2">
                                          <button
                                                onClick={() => toggleFaq(index)}
                                                className="w-full text-left p-2 bg-gray-800/80 rounded-lg flex justify-between items-center font-poppins"
                                          >
                                                <span className="font-semibold flex items-center">
                                                      <HelpCircle size={18} className="mr-2" />
                                                      {item.question}
                                                </span>
                                                {openFaq === index ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                                          </button>
                                          <div
                                                className={`overflow-hidden transition-all duration-300 ease-in-out ${openFaq === index ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'
                                                      }`}
                                          >
                                                <div className="p-2 bg-gray-700/50 rounded-lg mt-1 text-sm font-poppins">
                                                      {item.answer}
                                                </div>
                                          </div>
                                    </div>
                              ))}
                        </div>
                  </div>
            </div>
      );
};

const NotificationArea = ({ notification }) => {
      const [isVisible, setIsVisible] = useState(true);
      const [currentNotification, setCurrentNotification] = useState(null);

      useEffect(() => {
            if (notification) {
                  setIsVisible(false);
                  setTimeout(() => {
                        setCurrentNotification(notification);
                        setIsVisible(true);
                  }, 300);
            } else {
                  setIsVisible(false);
                  setTimeout(() => {
                        setCurrentNotification(null);
                        setIsVisible(true);
                  }, 300);
            }
      }, [notification]);

      const WarningMessage = () => (
            <div className="flex items-center justify-center bg-yellow-500/20 p-3 rounded-lg text-sm font-semibold text-yellow-500 text-center w-full font-poppins">
                  <AlertTriangle className="mr-2 flex-shrink-0" size={20} />
                  <span>Nous ne tolérons pas les propos injurieux, racistes, homophobes, sexistes, etc !</span>
            </div>
      );

      const NotificationMessage = () => (
            <div className={`flex items-center justify-center p-3 rounded-lg text-sm font-semibold ${currentNotification.type === 'error' ? 'bg-red-500/20 text-red-500' : 'bg-green-500/20 text-green-500'} text-center w-full font-poppins`}>
                  {currentNotification.type === 'error' ? <AlertCircle className="mr-2 flex-shrink-0" size={20} /> : <CheckCircle className="mr-2 flex-shrink-0" size={20} />}
                  <span>{currentNotification.message}</span>
            </div>
      );

      return (
            <div className="w-full h-16 overflow-hidden">
                  <div className={`transition-all duration-300 ease-in-out ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
                        {currentNotification ? <NotificationMessage /> : <WarningMessage />}
                  </div>
            </div>
      );
};

const InfoBox = ({ title, count, icon: Icon, color, bgColor }) => (
      <div className={`flex-1 ${bgColor} rounded-xl p-2 text-center font-poppins`}>
            <div className={`text-xs font-semibold mb-1 ${color}`}>{title}</div>
            <div className="flex justify-between items-center">
                  <span className={`text-xl font-extrabold ${color}`}>{count}</span>
                  <Icon className={color} size={20} />
            </div>
      </div>
);

export default B112LandingPage;